<template>
    <div class="w-full h-full flex flex-col overflow-y-auto bg-gray-100 ">
        <div 
            v-if="article"
            class="max-w-2xl my-16 mx-auto article">
            
            <h1 v-html="article.fields.heading" />
            
            <em class="text-xl my-8 block">
                {{article.fields.intro}}
            </em>
            
            <div class="mb-8">
                <div class="">
                    {{article.fields.author.fields.name}}. {{ article.fields.writtenAt | moment('LL') }}
                </div>
            </div>
            
            <img 
                v-if="article.fields.image && article.fields.image.fields && article.fields.image.fields.file && article.fields.image.fields.file.url"
                :src="`${article.fields.image.fields.file.url}?w=800&h=800`" 
                class="mb-4 w-full object-cover"
            />
            
            <NoteType 
                v-for="(node, aN) in article.fields.content.content"
                :key="'aN_'+aN"
                :node="node"
            />
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            'NoteType': () => import('@/components/NoteType.vue'),
        },
        
        computed: {
            articles(){
                return this.$store.getters.getArticles;
            },
            
            article(){
                if (this.articles) {
                    return this.$store.getters.getArticles.find( a => a.sys.id == this.articleId);
                }
                return null;
            },
        },
        data(){
            return {
                loading: true,
                articleId: null,
            }
        },
        
        mounted() {
            this.articleId = this.$route.params.articleId;
            
            if ( !this.articles ) {
                this.$store.dispatch('listArticles');
            }
            
        }
    }
</script>

<style lang="scss">
    .article {
        h1 {
            @apply text-6xl;
            @apply font-bold;
            @apply text-black;
            @apply leading-none;
            @apply mt-8;
            @apply mb-4;
        }
        
        h2 {
            @apply text-3xl;
            @apply text-black;
            @apply mt-8;
            @apply mb-4;
        }
        
        h3 {
            @apply text-xl;
            @apply text-black;
            @apply mt-8;
            @apply mb-4;
        }
        
        p {
            @apply text-lg;
        }
        
        a {
            @apply text-blue-500;
            @apply border-b;
            @apply border-blue-500;
            
            &:hover {
                @apply text-blue-800;
            }
        }
        
        img {
            @apply w-full;
        }
        
        .iframeWrapper {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            @apply my-8;
            
            
            iframe {
                position: absolute;
                top: 0; 
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
